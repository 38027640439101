.Subscribe {
    min-height: 100vh;
    padding: var(--size-700) var(--size-400) var(--size-1600);
    width: 100%;
    overflow: hidden;
    img {
        width: 120%;
        display: block;
        height: auto;
    }
    .lottie {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 99;
        width: 100%;
        height: 150%;
    }
    .TextBox {
        max-width: 500px;
        text-align: center;
    }
    .Hero {
        max-width: 1200px;
        margin-bottom: var(--size-700);
        width: 100%;
        h1 {
            font-size: 7vh;
            background: linear-gradient(90deg, #00ebff, #2f4dc6);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    form {
        display: flex;
        flex-direction: row;
        gap: var(--size-200);
        max-width: 500px;
        width: 100%;
        button {
            min-width: 120px;
        }
        & + .TextBox {
            max-width: 400px;
        }
    }
}
@media only screen and (max-width: 780px) {
    .Subscribe {
        img {
            width: 100%;
            margin-top: var(--size-300);
        }
        .Hero {
            h1 {
                font-size: 44px !important;
                max-width: 100%;
            }
        }
        h2 {
            font-size: var(--size-400) !important;
            max-width: 100%;
        }
    }
}
@media only screen and (max-width: 490px) {
    .Subscribe {
        padding: var(--size-700) var(--size-200) var(--size-1600);
        .Hero {
            h1 {
                font-size: 30px !important;
            }
        }
        h2 {
            font-size: var(--size-300) !important;
        }
        form {
            flex-direction: column;
            button {
                width: 100%;
            }
        }
    }
}
