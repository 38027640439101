@font-face {
    font-family: "OpenSans";
    src:
        url("opensans/OpenSans.woff2") format("woff2"),
        url("opensans/OpenSans.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "OpenSans-Medium";
    src:
        url("opensans/OpenSans-Medium.woff2") format("woff2"),
        url("opensans/OpenSans-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans-SemiBold";
    src:
        url("opensans/OpenSans-SemiBold.woff2") format("woff2"),
        url("opensans/OpenSans-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans-Light";
    src:
        url("opensans/OpenSans-Light.woff2") format("woff2"),
        url("opensans/OpenSans-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans-Bold";
    src:
        url("opensans/OpenSans-Bold.woff2") format("woff2"),
        url("opensans/OpenSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
//

@font-face {
    font-family: "Lobster";
    src:
        url("lobster/Lobster-Regular.woff2") format("woff2"),
        url("lobster/Lobster-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RedHatDisplay";
    src:
        url("redhatdisplay/RedHatDisplay-Light.woff2") format("woff2"),
        url("redhatdisplay/RedHatDisplay-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DarkerGrotesque";
    src:
        url("darker/DarkerGrotesque-Regular.woff2") format("woff2"),
        url("darker/DarkerGrotesque-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DarkerGrotesque-Bold";
    src:
        url("darker/DarkerGrotesque-Bold.woff2") format("woff2"),
        url("darker/DarkerGrotesque-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Pacifico";
    src:
        url("pacifico/Pacifico-Regular.woff2") format("woff2"),
        url("pacifico/Pacifico-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lora";
    src:
        url("lora/Lora-Regular.woff2") format("woff2"),
        url("lora/Lora-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

//

@font-face {
    font-family: "Spectral-Bold";
    src:
        url("spectral/Spectral-Bold.woff2") format("woff2"),
        url("spectral/Spectral-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Spectral";
    src:
        url("spectral/Spectral-Regular.woff2") format("woff2"),
        url("spectral/Spectral-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

//

@font-face {
    font-family: "Lato";
    src:
        url("lato/Lato-Regular.woff2") format("woff2"),
        url("lato/Lato-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato-Bold";
    src:
        url("lato/Lato-Bold.woff2") format("woff2"),
        url("lato/Lato-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

//

@font-face {
    font-family: "IBMPlexSans";
    src:
        url("ibm/IBMPlexSans-Regular.woff2") format("woff2"),
        url("ibm/IBMPlexSans-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "IBMPlexSans-Bold";
    src:
        url("ibm/IBMPlexSans-Bold.woff2") format("woff2"),
        url("ibm/IBMPlexSans-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
