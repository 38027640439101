.GradientPicker {
    width: 232px;
    background-color: var(--background-neutral-container);
    padding: 0;
    .padding {
        padding: 0 var(--size-200);
    }
    .inputfield {
        input {
            min-width: 1px;
        }
    }
    .SelectTrigger {
        min-width: 1px;
        width: 100%;
        margin-top: 8px;
    }
    label {
        color: var(--foreground-neutral);
        font-size: 16px;
        line-height: 1;
        display: flex;
        flex-direction: column;
        gap: var(--size-100);
        &.angle {
            flex-direction: row;
            align-items: center;
            span.angleValue {
                min-width: 48px;
                text-align: right;
            }
        }
    }
    .SliderThumb {
        background-color: var(--white);
    }
}

.custom-range-slider {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.colorButton {
    width: 100%;
    flex: 1;
    display: flex;
    height: 44px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border: 1px solid var(--border-neutral-subtle);
    border-radius: var(--size-50);
    background-color: var(--white);
    &[aria-pressed="true"] {
        border: 2px solid var(--brand-2);
    }
    &:hover,
    &:focus-visible {
        box-shadow: var(--focus);
    }
    & > div {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}
.gradientSlider {
    position: relative;
    height: 24px;
    border-radius: var(--size-50);
    border: 1px solid var(--border-neutral-subtle);
    background-color: var(--white);
    width: 100%;
    box-sizing: border-box;
    .gradientbg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: var(--size-50);
    }
}

.colorHandle {
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background-color: var(--white);
    border: 1px solid var(--border-neutral-subtle);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 100%;
    .handle {
        position: absolute;
        top: calc(100% + 4px);
        left: 50%;
        transform: translate(-50%, 0%);
        width: 24px;
        height: 24px;
        border: 1px solid var(--border-neutral-subtle);
        border-radius: var(--size-50);
        overflow: hidden;
        cursor: pointer;
        background-color: var(--white);
        & > div {
            width: 100%;
            height: 100%;
        }
    }
}

.custom-range-slider label {
    margin-bottom: 5px;
}

.custom-range-slider .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.custom-range-slider .slider:hover {
    opacity: 1;
}

.custom-range-slider .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #000;
    cursor: pointer;
}

.custom-range-slider .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #000;
    cursor: pointer;
}
